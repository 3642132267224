<template>
  <div>
    <h2 class="section-header">
      <!-- <img src="@/assets/images/account-black.svg" alt="account" /> -->
      Profile
    </h2>

    <div class="form">
      <h3>Update Your Profile</h3>
      <div class="fields">
        <div class="primary-fields">
          <div class="line-group">
            <input
              type="text"
              placeholder="First Name"
              v-model="firstName"
              disabled
            />

            <input
              type="text"
              placeholder="Last Name"
              v-model="lastName"
              disabled
            />
          </div>

          <div class="line-group">
            <div class="input-group">
              <input
                type="tel"
                maxlength="11"
                placeholder="Phone"
                @input="updatePhoneInput"
                :value="phoneNumber"
                
              />
              <p v-if="phoneNumberError.length > 0" class="error">
                {{ phoneNumberError }}
              </p>
            </div>

            <input type="email" placeholder="Email" v-model="email" disabled />
          </div>

          <div class="line-group">
            <div class="input-group">
              <select name="" id="" v-model="countryId" @change="fetchState()">
                <option value="">Choose Country</option>
                <option
                  v-for="country in countries"
                  :key="country.id"
                  :value="country.id"
                  >{{ country.name }}
                </option>
              </select>
              <p v-if="countryError.length > 0" class="error">
                {{ countryError }}
              </p>
            </div>

            <div class="input-group">
              <select name="" id="" v-model="stateId" @change="fetchCities()">
                <option value="">Choose State</option>
                <option
                  v-for="state in states"
                  :key="state.id"
                  :value="state.id"
                >
                  {{ state.name }}
                </option>
              </select>
              <p v-if="stateError.length > 0" class="error">{{ stateError }}</p>
            </div>

            <div class="input-group">
              <select name="" id="" v-model="cityId">
                <option value="">Choose City</option>
                <option
                  v-for="city in cities"
                  :key="city.id"
                  :value="city.id"
                >
                  {{ city.name }}
                </option>
              </select>
              <p v-if="cityError.length > 0" class="error">{{ cityError }}</p>
            </div>
          </div>

          <div class="line-group">
            <div class="input-group mt-4">
              <label for="">KYC status</label>
              <h6 class="kycs">{{ kycApproval }}</h6>
              
            </div>
            
          </div>

          <div class="line-group" v-if="kycDisapproval != null && kycDisapproval != ''">
            <div class="input-group mt-4">
              <label for="">KYC disapproving reason</label>
              <h6 class="kycs">{{ kycDisapproval }}</h6>
              <!-- <h6>{{kycDisapproval  }}</h6> -->
              <!-- <textarea
                type="text"
                placeholder="KYC disapproving staus"
                :value="kycDisapproval"
                disabled
              ></textarea> -->
              
            </div>
          </div>

          <button class="add-more" @click="addMore = !addMore" v-if="!addMore">
            <span class="add-icon">&plus;</span>Add more details
          </button>

          <button class="add-more" @click="addMore = !addMore" v-else>
            <span class="add-icon">&minus;</span>Close details
          </button>
        </div>

        <div v-if="addMore" class="secondary-fields">
          <div class="line-group">
            <div class="file-upload">
              <p>Upload means of identification</p>
              <p
                class="file-status"
                v-if="identificationDocStatus === 'processing'"
              >
                File uploading...
              </p>
              <p
                class="file-status"
                v-if="identificationDocStatus === 'success'"
              >
                File uploaded successfully
              </p>
              <div class="file-group">
                <div class="input-file">
                  <input
                    type="file"
                    @change="uploadDoc($event)"
                    accept="image/*, .pdf"
                  />
                  <button>Choose file</button>
                </div>
                <p>{{ identificationDoc }}</p>
              </div>
            </div>

            <div class="file-upload">
              <p>Upload profile photo</p>
              <p class="file-status" v-if="profilePicStatus === 'processing'">
                File uploading...
              </p>
              <p class="file-status" v-if="profilePicStatus === 'success'">
                File uploaded successfully
              </p>
              <div class="file-group">
                <div class="input-file">
                  <input
                    type="file"
                    @change="uploadPic($event)"
                    accept="image/*"
                  />
                  <button>Choose file</button>
                </div>
                <p>{{ profilePic }}</p>
              </div>
            </div>
          </div>

          <div class="line-group">
            <input type="text" placeholder="Occupation" v-model="occupation" :disabled="userData.verified == '1'" />

            <input
              :disabled="userData.verified == '1'"
              type="text"
              placeholder="Account Name"
              v-model="accountName"
            />
          </div>

          <div class="line-group">
            <input
              :disabled="userData.verified == '1'"
              type="text"
              placeholder="Account number"
              v-model="accountNumber"
            />

            <!-- <input type="text" placeholder="Bank name" v-model="bankName"> -->

            <select name="" id="" v-model="bankName">
              <option value="" selected disabled>Choose Bank</option>
              <option v-for="bank in banks" :key="bank" :value="bank" :disabled="userData.verified == '1'">
                {{ bank }}
              </option>
            </select>
          </div>
        </div>

        <button
          class="update"
          @click="updateCredentials()"
          :disabled="processing"
        >
          <img src="@/assets/images/update-icon.svg" alt="" />
          {{ processing ? "Updating..." : "Update Profile" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import getLocation from "../services/getLocation";
import userCredentials from "../services/userCredentials";
import Validators from "../helpers/Validators";
import directory from "../services/directory";
// import InputSelect from "../components/form/InputSelect.vue";
// import BaseButtton from "../components/UI/BaseButtton.vue";
export default {
  name: "Account",
  data() {
    return {
      userData: "",
      addMore: false,
      userID: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      phoneNumberError: "",
      email: "",
      countryId: "",
      countryError: "",
      stateId: "",
      stateError: "",
      cityError: "",
      identificationDoc: "",
      identificationDocStatus: "", //empty string, 'processing', 'success'
      profilePic: "",
      profilePicStatus: "", // empty string, 'processing', 'success'
      occupation: "",
      accountName: "",
      accountNumber: "",
      bankName: "",
      banks: [],
      countries: [],
      states: [],
      processing: false,
      cityId: "", 
      cities: [],
      kycDisapproval: "",
      kycApproval: ""
    };
  },
  methods: {
    updatePhoneInput(e) {
      this.phoneNumber = e.target.value;
    },

    fetchState() {
      //fetch states in selected country
      // this.$toast.info("fetching states...")
      getLocation
        .getStates(this.countryId)
        .then(data => {
          if (data.success) {
            this.states = data.states;
            // this.$toast.success("States successfully fetched")
          } else {
            // this.$toast.error(data.error);
          }
        })
        .catch(() => {
          this.$toast.error("please check your network and refresh the page");
        });
    },
    fetchCities() {
      getLocation
        .getCities(this.stateId)
        .then((data) => {
          this.cities = data.cities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    uploadDoc(event) {
      const file = event.target.files[0];
      if (file.size > 1024 * 500) {
        //if file size is greater than 500kb
        return this.$toast.error("Document must not be greater than 500kb");
      }

      if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/svg+xml" &&
        file.type !== "application/pdf"
      ) {
        return this.$toast.error(
          "Document must only be in either image or pdf format"
        );
      } else {
        this.identificationDocStatus = "processing";

        let formData = new FormData();

        formData.append("id", +this.userID);
        formData.append("file", file);
        formData.append("documentType", file.name);

        userCredentials
          .userIdentity(formData)
          .then(data => {
            if (data.success) {
              this.identificationDocStatus = "success";
              this.identificationDoc = file.name;
              this.$store.dispatch("setUserMeansOfIdFile", data.filename);
            } else {
              this.identificationDocStatus = "";
              this.$toast.error(data.error);
            }
          })
          .catch(() => {
            this.identificationDocStatus = "";
            this.$toast.error("please check your network and refresh the page");
          });
      }
    },
    uploadPic(event) {
      const file = event.target.files[0];
      if (file.size > 1024 * 200) {
        //if file size is greater than 200kb
        return this.$toast.error("Picture must not be greater than 200kb");
      }
      if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/svg+xml"
      ) {
        return this.$toast.error(
          "Picture must be in either jpg, jpeg, png or svg format only"
        );
      } else {
        this.profilePicStatus = "processing";

        let formData = new FormData();

        formData.append("id", +this.userID);
        formData.append("file", file);

        userCredentials
          .profilePic(formData)
          .then(data => {
            if (data.success) {
              this.profilePic = file.name;
              this.profilePicStatus = "success";
              this.$store.dispatch("setUserPassportName", data.filename);
              this.$store.dispatch("setUserPassportFile", data.url);
            } else {
              this.profilePicStatus = "";
              this.$toast.error(data.error);
            }
          })
          .catch(() => {
            this.profilePicStatus = "";
            this.$toast.error("please check your network and refresh the page");
          });
      }
    },

    getBanks() {
      directory
        .fetchBanks()
        .then(data => {
          this.banks = data.banks;
        })
        .catch(() => {
          this.$toast.error("please check your network and refresh the page");
        });
    },
    updateCredentials() {
      const forPhoneNumber = new Validators();
      forPhoneNumber.onlyNumbers(this.phoneNumber);
      this.phoneNumberError = forPhoneNumber.errors;

      const forCountry = new Validators();
      forCountry.required(this.countryId + "");
      this.countryError = forCountry.errors;

      const forState = new Validators();
      forState.required(this.stateId + "");
      this.stateError = forState.errors;

      const forCity = new Validators();
      forCity.required(this.cityId + "");
      this.cityError = forCity.errors;

      if (
        this.phoneNumberError.length === 0 &&
        this.countryError.length === 0 &&
        this.stateError.length === 0 && 
        this.cityError.length === 0
      ) {
        let body = {
          id: this.userID,
          phone: this.$store.state.user.phone
            ? this.$store.state.user.phone
            : this.phoneNumber,
          countryid: this.countryId,
          stateid: this.stateId,
          cityid: this.cityId
        };

        if (this.addMore === true) {
          body.realtoroccupation = this.occupation;
          body.accountname = this.accountName;
          body.accountnumber = this.accountNumber;
          body.bankname = this.bankName;
        }
        const user = this.$store.getters.getUser;
        if (user.meansofidfile && user.meansofidfile.length > 0) {
          body.meansofidentity = user.meansofidfile;
        }

        if (user.passportfile && user.passportfile.length > 0) {
          body.passportfile = user.passportname;
        }

        body = JSON.stringify(body);

        this.processing = true;
        userCredentials
          .update(body)
          .then(data => {
            if (data.success) {
              const realtor = data.realtor;
              realtor.token = user.token;
              realtor.meansofidfile = user.meansofidfile;
              realtor.passportfile = user.passportfile;
              realtor.stateObject = user.stateObject;
              this.$store.dispatch("saveUser", realtor);
              this.$toast.success("Your profile has been successfully updated");
            } else {
              this.$toast.error(data.error);
            }
          })
          .catch(() => {
            this.$toast.error("please check your network and refresh the page");
          })
          .finally(() => {
            this.processing = false;
          });
      }
    }
  },
  created() {
    // get user detials from store.
    let user = this.$store.getters.getUser;
    // console.log("User", user)
    this.userData = user;
    this.userID = user.id;
    this.firstName = user.firstname;
    this.lastName = user.lastname;
    this.phoneNumber = user.phone;
    this.email = user.email;
    this.occupation = user.realtoroccupation;
    this.bankName = user.bankname;
    this.accountName = user.accountname;
    this.accountNumber = user.accountnumber;
    this.kycApproval = user.kyc_approval_status;
    this.kycDisapproval = user.kyc_disapproval_reason;

    if (!isNaN(user.countryid)) {
      this.countryId = user.countryid;
    }
    if (!isNaN(user.stateid)) {
      this.stateId = user.stateid;
      this.fetchState();
    }
    if (!isNaN(user.cityid)) {
      this.cityId = user.cityid;
      this.fetchCities();
    }

    // get list of countries from api
    // this.$toast.info("fetching countries...")
    getLocation
      .getCountries()
      .then(data => {
        if (data.success) {
          this.countries = data.countries;
          // this.$toast.success("Countries successfully fetched.")
        } else {
          // this.$toast.error(data.error);
        }
      })
      .catch(() => {
        this.$toast.error("please check your network and refresh the page");
      });

    this.getBanks();
  },
  watch: {
    phoneNumber() {
      if (this.phoneNumber != null) {
        this.phoneNumber = this.phoneNumber.replace(/[^0-9.]/g, "");
      }
    }
  }
};
</script>

<style scoped>
@import "../styles/section-header.css";
@import "../styles/forms.css";

.kycs {
  border: 1px solid grey;
  padding: 10px;
  border-radius: 6px;
  word-wrap: break-word;
  line-height: 26px;
  width: 100%;
}
</style>
